<template>

  <div class="">
    <form>
      <div class="form">
        <div class="form-group row">
          <div class="col-md-6">
            <label class="col-md-12">Seleccione Paciente :*</label>

            <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
              <input required type="text" class="form-control" v-model="nome_pesquisa" />
              <b-button v-b-modal.SelecionarPaciente class="btn btn-indo" @click="pesquisarPaciente()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </b-button>
            </b-input-group>

            <div>
              <b-modal id="SelecionarPaciente" hide-footer hide-header title="SelecionarPaciente" ref="modal_paciente">
                <input required type="text" class="form-control" v-model="nome_pesquisa" />
                <b-table :fields="['nome', 'RG/CI', 'cpf', 'acoes']" :items="lista_usuarios_matriculas"
                  :per-page="perPage" :current-page="currentPage" id="atendimento-table" class="table table-hover"
                  show-empty empty-text="Nenhum registro encontrado!">
                  <template #cell(acoes)="{ item }">
                    <button @click="selecionarPaciente(item)" class="btn btn-light mx-1" v-b-tooltip.hover
                      title="Clique para selecionar paciente">
                      Selecionar
                    </button>
                  </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="lista_usuarios_matriculas.length" :per-page="perPage"
                  aria-controls="atendimento-table">
                </b-pagination>
              </b-modal>
            </div>
          </div>
          <div class="col-md-6">
            <label class="col-md-12">Tipo:*</label>
            <select v-model="form.tipo_atendimento_id" class="form-control">
              <option value="1">Consulta</option>
              <option value="2">Retorno</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-3">
            <label class="col-md-12"> Fecha:*</label>
            <input required type="date" class="form-control" v-model="form.data" />
          </div>
          <div class="col-md-2">
            <label class="col-md-12"> Hora:*</label>
            <input required type="time" class="form-control" v-model="form.hora" />
          </div>

        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12"> Descripción:</label>
            <input required type="text" class="form-control" v-model="form.descricao" placeholder="..." />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12 text-center">
            <button @click.prevent="
              confirm(lista_campos == '' ? 'crea' : 'Edita')
            " class="btn btn-primary" :disabled="verif">
              Salvar
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</template>
<script>
$(document).ready(function () {
  $("#estado").select2();
});
</script>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      // selectedUser: "escolha",
      // selectedFuncionario: null,
      nome_pesquisa: "",
      disableSaveModal: false,
      usuario_id: null,
      form: {
        usuario_id: null,
        status: 1,
        descricao: "",
        data: "",
        hora: "",
        funcionario_id: null,
        tipo_atendimento_id: null,
        vinculo_sala_especialidade_id: null,
      },
      verif: false,
      option: "",
      currentPage: 1,
      perPage: 7,
    };
  },
  watch: {
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
        var p = { texto: this.nome_pesquisa, status: 3 };
        this.$store.dispatch("configUsuarios/pesquisar_usuarios", p);
      }
    },
  },
  created() {
    // this.listar_requerimentos();
    this.preenxerCampos();
    this.listar_usuarios_matriculas();
  },
  computed: {
    lista_vinculo_especialidade() {
      return this.$store.state.configEmpresa.lista_vinculo_especialidade;
    },
    lista_usuarios_matriculas() {
      return this.$store.state.configUsuarios.lista_usuarios_matriculas;
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    vinculo_id(){
      return this.$store.state.configEmpresa.lista_dados_guardados;
    }
  },
  methods: {
    // searchUsuarios({ searchQuery, callback }) {
    //   let data =
    //     this.$store.state.configUsuarios.lista_usuarios_matriculas.filter(
    //       (item) => {
    //         if (!searchQuery) return true;
    //         return (
    //           String(item["label"])
    //             .toLowerCase()
    //             .indexOf(searchQuery.toLowerCase()) !== -1
    //         );
    //       }
    //     );

    //   console.log(data);
    //   let options = data.map((usuario) => ({
    //     id: usuario.id,
    //     label: usuario.nome,
    //   }));
    //   callback(null, options);
    // },
    // searchFuncionarios({ searchQuery, callback }) {
    //   let data = this.$store.state.configUsuarios.lista_usuarios.filter(
    //     (item) => {
    //       if (!searchQuery) return true;
    //       return (
    //         String(item["label"])
    //           .toLowerCase()
    //           .indexOf(searchQuery.toLowerCase()) !== -1
    //       );
    //     }
    //   );

    //   console.log(data);
    //   let options = data.map((usuario) => ({
    //     id: usuario.id,
    //     label: usuario.nome,
    //   }));
    //   callback(null, options);
    // },
    async pesquisarPaciente() {
      var p = { texto: this.nome_pesquisa, status: 3 };
      await this.$store.dispatch("configUsuarios/pesquisar_usuarios", p);
    },
    async selecionarPaciente(value) {
      this.form.usuario_id = value.id;
      this.nome_pesquisa = value.nome;
      this.$refs["modal_paciente"].hide();
    },

    async listar_usuarios_matriculas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configUsuarios/listar_usuarios", 2);
      await this.$store.dispatch(
        "configEmpresa/list_vinculo_sala_especialidade"
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },

    async listar_pacientes() {
      await this.$store.dispatch("configUsuarios/listar_usuarios_perfil", 3);
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un atendimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "crea") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      if (this.form.usuario_id) {
        await this.$store
          .dispatch("atendimento/create_atendimento", this.form)
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"

            this.fireAlert(this.mensagem_alert);
            this.$router.push({
              name: "createOcorrencia",
              params: {
                atendimento_id: this.$store.state.atendimento.atendimento_id,
              },
            });
          });
      } else {
        this.fireAlert({
          title:
            "Ningún paciente seleccionado! Clicka en el ícono de búsqueda y seleccione un paciente primero.",
          tipo: "error",
          timer: 5000,
        });
      }

      this.verif = false;
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("atendimento/update_atendimento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "atendimento",
      });
    },

    preenxerCampos() {
      this.form.vinculo_sala_especialidade_id = this.vinculo_id
      if (this.lista_campos != "")
        this.selectedUser = this.lista_campos.paciente;
      (this.form.id = this.lista_campos.id),
        (this.form.usuario_id = this.lista_campos.usuario_id),
        (this.form.funcionario_id = this.lista_campos.funcionario_id),
        (this.form.data = this.lista_campos.data),
        (this.form.hora = this.lista_campos.hora);
      this.form.descricao = this.lista_campos.descricao;
      this.form.tipo_atendimento_id = this.lista_campos.tipo_atendimento_id;
      this.nome_pesquisa = this.lista_campos.paciente

      console.log(this.form)
    },

  
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>