<template>
  <div>

    <div>
      <div class="container">
        <div class="row">
          <div v-for="(l, index) in lista_vinculos" :key="index" class="col-md-6">
            <b-card title="" header-tag="header" footer-tag="footer">

              <template v-slot:header>
                <div class=" d-flex justify-content-between">
                  <div class="col-md-8">
                    <h6 class="mb-0">{{ l.sala }}</h6>





                  </div>


                  <b-button @click="criarAtendimento(l.id)" class="btn btn-sm" v-b-modal.atendimento>
                    <span class="fas fa-plus"></span>
                  </b-button>

                  <b-modal class="modal modal-lg" size="lg" hide-footer centered id="atendimento"
                    ref="modal_atendimento" title="Atendimento">
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-group label="Atendimento:*">
                          <FormAtendimento></FormAtendimento>
                        </b-form-group>
                      </div>
                    </div>

                  </b-modal>



                </div>



              </template>
              <b-card-text></b-card-text>
              <template v-slot:footer>

              </template>
            </b-card>

          </div>
          <!-- <div class="col-md-4">
            <b-card title="" header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">CONSULTORIO</h6>
              </template>
              <b-card-text></b-card-text>

              <template v-slot:footer>

              </template>
            </b-card>

          </div>
          <div class="col-md-4">
            <b-card title="" header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">CONSULTORIO</h6>
              </template>
              <b-card-text></b-card-text>

              <template v-slot:footer>

              </template>

            </b-card>
          </div> -->


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import FormAtendimento from "@/view/components/atendimento/formAtendimento.vue";

export default {
  components: {
    FormAtendimento
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Agendamentos" }]);
  },
  created() {
    this.listar_vinculos()
    console.log(this.lista_vinculos);
  },
  computed: {
    lista_vinculos() {
      return this.$store.state.configEmpresa.lista_vinculo_especialidade
    }
  },
  methods: {
    async listar_vinculos() {
      await this.$store.dispatch("configEmpresa/list_vinculo_sala_especialidade")

    },
    async criarAtendimento(value) {
      console.log(value)
      await this.$store.dispatch("configEmpresa/guardarDados",value)
    }
  }
}
</script>

<style>

</style>